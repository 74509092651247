import { useLoaderData, useParams, useSubmit, Form } from "react-router-dom";
import React, { useEffect } from 'react';
import ErrorPage from "./ErrorPage";
import TypoGraphy from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import InputAdornment from '@mui/material/InputAdornment';
import { Anchorme } from 'react-anchorme'
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import { Avatar, Box, Grid } from "@mui/material";
import TextFieldWithCharacterLimit from "./TextFieldWithCharacterLimit";
import AccordionList from "./AccordionList";
import { getSrcFromUserId } from "../config";
import ButtonMenu from "./ButtonMenu";
import AddIcon from '@mui/icons-material/Add';

export default function Review() {
    const review = useLoaderData();
    const { Id } = useParams();
    const submit = useSubmit();

    const [resetState, setResetState] = React.useState({comment: 0, tag: 0});

    let isEventProcessing = false;

    useEffect(() => {
        document.getElementById("CommentInput").addEventListener('keydown', function (e) {
            if (e.keyCode === 13 || e.keyCode === 10) {
                // Ctrl + Enter
                if(e.ctrlKey && !isEventProcessing) {
                    isEventProcessing = true
                    console.log('ctrl+enter');

                    let savedText = document.getElementById("CommentInput").value
                    
                    let formData = new FormData();
                    formData.append("CommentInput", savedText);
                    submit(formData, { method: "post" });

                    handleReset("comment")
                    setTimeout(() => {
                        isEventProcessing = false
                    }, 50);
                } 
            }
        });
    }, [])

    const handleReset = (name) => {
        setResetState({...resetState, [name]: resetState[name] + 1 })
    }

    if (!review || review.length === 0) {
        return <ErrorPage errorText={"No review with the ID '" + Id + "' found."} />
    }

    return (
        <>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <TypoGraphy variant="h3">
                            {review.ArtistName + " - " + review.TrackName}
                        </TypoGraphy>
                        <br />
                        <Chip label={review.ReviewStatus.Name} style={{marginBottom: "5px", color: review.ReviewStatus.Color }} />
                        {
                            getAssignedChipsWithClick(review, submit)
                        }
                        {
                            getTagChips(review)
                        }
                        <br />
                        <br />
                    </Box>
                </Grid>

                <Grid item xs={12} md={2}>
                    <Form>
                        <ButtonMenu
                            header={review.ReviewStatus.Name}
                            buttonId="ReviewStatusButton"
                            options={review.ReviewStatus.Transitions}
                            onItemClick={(option) => onMenuItemClick(option, submit)}
                        />
                    </Form>
                </Grid>

                <Grid item xs={12} md={7}>
                    <Form method="post" >
                        <TextFieldWithCharacterLimit
                            id="CommentInput"
                            label="Add a comment:"
                            placeholder="comment text:"
                            name="CommentInput"
                            multiline
                            fullWidth
                            resetState={resetState.comment}
                            maxLength={500}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton type="submit" 
                                        onClick={
                                            () => setTimeout(() => {
                                                handleReset("comment")
                                            }, 500)
                                            }>
                                            <SaveIcon></SaveIcon>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Form>
                </Grid>

                <Grid item xs={12} md={3}>
                    <Form method="post" >
                        <TextFieldWithCharacterLimit
                            id="TagInput"
                            label="Add a tag:"
                            placeholder="tag text:"
                            name="TagInput"
                            fullWidth
                            resetState={resetState.tag}
                            maxLength={25}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton type="submit" 
                                        onClick={
                                            () => setTimeout(() => {
                                                handleReset("tag")
                                            }, 500)
                                            }>
                                            <SaveIcon></SaveIcon>
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Form>
                </Grid>

                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <AccordionList
                            data={review.Comments}
                            getPrimaryText={getPrimaryText}
                            getSecondaryText={getSecondaryText}
                            defaultExpanded
                            header={"Comments"}
                            getAvatarSrc={getAvatarSrc}
                        />
                        <br />

                        <AccordionList
                            data={review.StatusUpdates}
                            getPrimaryText={getPrimaryText}
                            getSecondaryText={getSecondaryText}
                            header={"Status transitions"}
                            getAvatarSrc={getAvatarSrc}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export const getTagChips = (review, withMargin = true) => {
    return review.Tags.map((tag) => {
        return <Chip label={tag} sx={{marginBottom: withMargin ? "5px" : "", marginLeft: "10px" }} />
    })
}

const getAvatarSrc = (interaction) => {
    return getSrcFromUserId(interaction.UserId)
}

const getPrimaryText = (interaction) => {
    return "(" + interaction.DateText + ") " + interaction.Header + ":"
}

const onMenuItemClick = (option, submit) => {
    let formData = new FormData();
    formData.append("status", option.ToReviewStatusId);
    submit(formData, { method: "post" });
}

const getSecondaryText = (interaction) => {
    return (
        <TypoGraphy sx={{whiteSpace: "pre-wrap"}}>
            <Anchorme target="_blank" rel="noreferrer noopener" truncate={20}>
                {interaction.Body}
            </Anchorme>
        </TypoGraphy>
    )
}

export const getAssignedChipsWithClick = (review, submit) => {
    return (
        <>
            {review.NeedsHv
                ? <Chip sx={{marginBottom: "5px", marginLeft: "10px" }} avatar={<Avatar src={getSrcFromUserId(0)}></Avatar>} label="HIV" onDelete={() => handleOnDeleteClick(0, submit, false)} />
                : <Chip sx={{marginBottom: "5px", marginLeft: "10px", opacity: 0.5 }} variant="outlined" avatar={<Avatar src={getSrcFromUserId(0)}></Avatar>} label="Assign HIV" onDelete={() => handleOnDeleteClick(0, submit, true)} deleteIcon={<AddIcon />} />}

            {review.NeedsPint
                ? <Chip sx={{marginBottom: "5px", marginLeft: "10px" }} avatar={<Avatar src={getSrcFromUserId(1)}></Avatar>} label="PipiRaw" onDelete={() => handleOnDeleteClick(1, submit, false)} />
                : <Chip sx={{marginBottom: "5px", marginLeft: "10px", opacity: 0.5 }} variant="outlined" avatar={<Avatar src={getSrcFromUserId(1)}></Avatar>} label="Assign PipiRaw" onDelete={() => handleOnDeleteClick(1, submit, true)} deleteIcon={<AddIcon />} />}
        </>)
}

export const getAssignedChips = (review) => {
    return (
        <>
            {review.NeedsHv
                ? <Chip sx={{ marginLeft: "10px" }} avatar={<Avatar src={getSrcFromUserId(0)}></Avatar>} label="HIV" />
                : ""}

            {review.NeedsPint
                ? <Chip sx={{ marginLeft: "10px" }} avatar={<Avatar src={getSrcFromUserId(1)}></Avatar>} label="PipiRaw" />
                : ""}
        </>)
}

const handleOnDeleteClick = (userId, submit, defaultValue) => {
    let formData = new FormData();

    if (userId === 1) {
        formData.append("needsPint", defaultValue);
    }

    if (userId === 0) {
        formData.append("needsHv", defaultValue);
    }

    submit(formData, { method: "post" });
}
