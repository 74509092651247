import { postWithAuth, putWithAuth } from "../Helpers/authHelper";
import { toast } from 'react-toastify';
import { redirect } from "react-router-dom";
import { API_URL } from "../config";

export async function updateReview({ request, params }) {

  let data = await request.formData()

  if (data.has("status")) {
    return putWithAuth(API_URL + "review/" + params.Id, { reviewStatusId: data.get("status") })
  }

  if (data.has("CommentInput")) {

    let comment = data.get("CommentInput")

    if (comment.trim() === '' || !comment) {
      toast.error("Please enter a comment text first.", { theme: "colored" })
      return null
    }

    if (comment.length > 500) {
      toast.error("A comment can't be longer than 500 characters.", { theme: "colored" })
      return null
    }

    return postWithAuth(API_URL + "review/" + params.Id + "/comment", { comment: comment })
  }

  if (data.has("needsHv") || data.has("needsPint")) {

    return putWithAuth(API_URL + "review/" + params.Id, {
      needsHv: data.get("needsHv"),
      needsPint: data.get("needsPint"),
    })
  }

  if (data.has("TagInput")) {

    let tagText = data.get("TagInput")

    if (tagText.trim() === '') {
      toast.error("Please enter a tag text first.", { theme: "colored" })
      return null
    }

    if (tagText.length > 25) {
      toast.error("A tag can't be longer than 25 characters.", { theme: "colored" })
      return null
    }

    return postWithAuth(API_URL + "review/" + params.Id + "/tag", { text: tagText})
  }

  return null
}

export async function createReview({ request, params }) {

  let data = await request.formData()

  let artistName = data.get("ArtistName").trim()
  let trackName = data.get("TrackName").trim()
  let error = false;

  if (artistName === '') {
    toast.error("Please enter an artist name.")
    error = true
  }

  if (trackName === '') {
    toast.error("Please enter a track name.")
    error = true
  }

  if (error) {
    return null
  }

  await postWithAuth(API_URL + "review/", { TrackName: trackName, ArtistName: artistName })
  return redirect("/reviews")
}